const BaseURL = 'https://app-end.taller24e.org/api';

const titles = {
  siteName: 'Taller 24E Plus',
}

const logos = {
  logoTopbar: '24eplus_horizontal_color.png',
  logoLogin: '24eplus_horizontal_color.png',
  backgroundLogin: 'background.jpg',
}
export { 
  BaseURL,
  titles,
  logos
}
